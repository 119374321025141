<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :records="tableData" @resetHandler="reset" @searchHandler="doSearch"
      @batchDeleteHandler="batchDelete" @addHandler="saveTripCompany" :loading="loading" :showDelete="false"
      :showAdd="canAdd">
      <template v-slot:search>
        <el-form-item label="公司名称：">
          <el-input v-model.trim="search.name" placeholder="请输入公司名称"></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="name" label="公司名称"></el-table-column>
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column prop="contactor" label="联系人"></el-table-column>
        <el-table-column prop="mobile" label="联系电话"></el-table-column>
        <el-table-column label="操作" width="100" v-if="canEdit || canDelete">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="queryById(scope.row.id)">编辑</el-button>
            <el-button type="text" size="small" @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </template>

      <template v-slot:operation="row">
        <el-button v-if="$store.state.user.companyNo != ''" type="text" size="small"
          @click="createAdminUser(row.record.id, row.record.type)">设置管理员</el-button>
      </template>
    </table-compnent>

    <el-dialog title="设置运营公司管理员" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="登录账号" :label-width="formLabelWidth">
          <el-col :span="8">
            <el-input v-model.trim="user.username" autocomplete="off" placeholder="请输入手机号..."></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-col :span="8">
            <el-input v-model.trim="user.realName" autocomplete="off" placeholder="请输入真实姓名..."></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doCreateAdminUser">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import baseList from '../../base/listPage.vue';
import TableCompnent from "@/components/table/TablesComponents";
import TripCompanyApi from "@/api/TripCompanyApi";
import SysUserApi from "@/api/admin";
export default {
  name: "TripCompanyList",
  extends: baseList,
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      search: {
        name: "",
      },
      user: {
        username: "",
        realName: "",
      },
      page: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({ pageSize: 20, pageNum: 1 })
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      TripCompanyApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripCompany() {
      this.navTo("/saveTripCompany");
    },
    queryById(id) {
      TripCompanyApi.queryTripCompanyById({ id: id }).then((resp) => {
        if (resp.code === '200') {
          this.queryParam("saveTripCompany", resp.data);
        }
      });
    },
    deleteById(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          TripCompanyApi.deleteTripCompany({ id: id }).then((resp) => {
            if (resp.code === '200') {
              this.$successMsg(resp.msg);
              this.doSearch(this.page);
            } else {
              this.$errorMsg(resp.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchDelete(params) {
      TripCompanyApi.batchDeleteTripCompany(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    createAdminUser() {
      this.dialogFormVisible = true;
    },
    doCreateAdminUser() {
      this.user.roleId = this.$store.state.user.roleId;
      this.user.companyNo = this.$store.state.user.companyNo;
      SysUserApi.saveAdminUser(this.user).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
          this.dialogFormVisible = false;
          this.user = {
            username: "",
            realName: "",
          };
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->